body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'UKNumberPlate';
  src: url('/public/fonts/UKNumberPlate.ttf') format('truetype');
}

@font-face {
  font-family: 'WindowsCommandPrompt';
  src: url('/public/fonts/WindowsCommandPrompt.ttf') format('truetype');
}

.plate-number {
  font-family: 'UKNumberPlate';
  font-size: 22px !important;
  margin: 0;
  padding: 0;
  color: #000 !important;
  text-shadow: 0 0 1px #fff;
}

.has-background-merlin  {
  background-image: url('../public/merlin-background.png');
  background-size: cover; /* This is the key property */
  background-position: center;
  background-repeat: no-repeat;
  position: absolute; /* or fixed, depending on your layout needs */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: -1; /* Place behind content if needed */
}

.barcode {
  font-family: 'WindowsCommandPrompt';
  font-size: 18px !important;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #000 !important;
  text-shadow: 0 0 1px #fff;
}