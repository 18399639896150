/* FullScreen Loader CSS */
.fullscreen-loader {
  position: fixed; /* Fixed position to cover the entire screen */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(255, 255, 255, 0.9); /* Light background for full screen */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

/* Container Loader CSS */
.container-loader {
  position: relative;
  width: 100%; /* Fill the width of the container */
  height: 100%; /* Fill the height of the container */
  background-color: rgba(255, 255, 255, 0.5); /* Light background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}